
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Cookies from 'universal-cookie'
import authSetting from '~/config/authSetting'
import MixinMemberId from '~/components/mixins/MixinMemberId'
import { API } from '~/types/api'

@Component({
  mixins: [MixinMemberId]
})
export default class MyBookshelf extends Vue {
  @Prop({
    type: Number,
    required: false
  })
  private articleId!: number

  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  private articlePage!: boolean

  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  private isSaved!: boolean

  @Prop({
    type: String,
    required: false
  })
  private used!: string

  private memberId!: number
  private localSaved: boolean = false
  private message: string = ''

  // リアクティブなデータとしてlocalSavedを定義
  data() {
    return {
      localSaved: false
    }
  }

  private get saved(): boolean {
    const favoriteStoryIds =
      this.$store.state.member.memberFollowing.favoriteStoryIds || []
    return favoriteStoryIds.includes(this.articleId)
  }

  mounted() {
    this.updateLocalSaved()
  }

  @Watch('$store.state.member.memberFollowing.favoriteStoryIds', {
    immediate: true,
    deep: true
  })
  onFavoriteStoryIdsChange() {
    this.updateLocalSaved()
  }

  // `used` が `article-reaction-bottom` の場合、常にチェックアイコンを表示
  private get articleBottomIcon(): boolean {
    return this.used === 'article-reaction-bottom'
  }

  private updateLocalSaved() {
    const favoriteStoryIds =
      this.$store.state.member.memberFollowing.favoriteStoryIds || []
    this.localSaved = favoriteStoryIds.includes(this.articleId)
  }

  private mouseOverAction() {
    this.localSaved
      ? (this.message = 'マイ本棚から削除')
      : (this.message = 'マイ本棚に追加')
  }

  private toggleBookshelf(event): void {
    event.stopPropagation()
    const previewPath = /^\/preview\//.test(this.$route.path)
    if (!previewPath && !this.memberId) {
      this.$store.dispatch('modal/changeNonMemberModalState', true)
    } else if (!previewPath) {
      this.saved ? this.delete() : this.submit()
      this.localSaved = !this.localSaved
    }
  }

  private async delete(): Promise<void> {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      await this.$axios
        .$get(csrfUrl, {
          auth: authSetting,
          withCredentials: true
        })
        .then((token: API.CSRF) => {
          this.deleteAction(token.csrf_token)
        })
    } catch (e) {
      return e
    }
  }

  private async deleteAction(csrf: string): Promise<void> {
    const cookies = new Cookies()
    const url = process.env.API_URL + `/members/${this.memberId}/favorite_story`
    const deleteData = {
      story_id: this.articleId
    }
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      data: deleteData,
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios
        .$delete(url, config)
        .then(() => {
          this.$store.dispatch('bookshelf/changeStoryIsSaved', this.articleId)
          this.$store.dispatch('member/updateFavoriteStoryIds', this.articleId)
        })
        .catch((err) => {
          // APIレスポンスが401だった場合
          const unauthorized =
            err.response.status === 401 || err.response.status === 404
          if (
            err.response &&
            unauthorized &&
            !cookies.get('unauthorized_error')
          ) {
            cookies.set('unauthorized_error', true)
            // sessionStorageのmemberId削除
            sessionStorage.removeItem('member')
            window.location.href = '/'
          }
        })
    } catch (err) {
      return err
    }
  }

  private async submit(): Promise<void> {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      await this.$axios
        .$get(csrfUrl, {
          auth: authSetting,
          withCredentials: true
        })
        .then((token: API.CSRF) => {
          this.submitAction(token.csrf_token)
        })
    } catch (e) {
      return e
    }
  }

  private async submitAction(csrf: string): Promise<void> {
    const cookies = new Cookies()
    const url = process.env.API_URL + `/members/${this.memberId}/favorite_story`
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    const postData = {
      story_id: this.articleId
    }
    try {
      await this.$axios
        .$post(url, postData, config)
        .then(() => {
          this.$store.dispatch('bookshelf/changeStoryIsSaved', true)
          this.$store.dispatch('member/updateFavoriteStoryIds', this.articleId)
        })
        .catch((err) => {
          // APIレスポンスが401だった場合
          const unauthorized =
            err.response.status === 401 || err.response.status === 404
          if (unauthorized) {
            cookies.set('unauthorized_error', true)
            this.logout()
          }
        })
    } catch (err) {
      return err
    }
  }

  private async logout(): Promise<void> {
    const csrfUrl = process.env.API_URL + '/members/get_csrf_token'
    try {
      await this.$axios
        .$get(csrfUrl, {
          auth: authSetting,
          withCredentials: true
        })
        .then((token: API.CSRF) => {
          this.logoutAction(token.csrf_token)
        })
    } catch (e) {
      return e
    }
  }

  private async logoutAction(csrf: string): Promise<void> {
    const url = process.env.API_URL + '/members/logout'
    const config = {
      headers: {
        'X-CSRF-Token': csrf
      },
      auth: authSetting,
      withCredentials: true
    }
    try {
      await this.$axios
        .$patch(url, {}, config)
        .then(() => {
          // sessionStorageのmemberId削除
          sessionStorage.removeItem('member')
        })
        .then(() => {
          const redirectUrl = `/`
          location.href = redirectUrl
        })
    } catch (e) {
      return e
    }
  }
}
